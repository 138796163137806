import React from 'react'
import { graphql } from 'gatsby'

import BackgroundImage from 'gatsby-background-image'
import BlockContent from '../components/block-content'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'

export const query = graphql`
  query AboutPageQuery {
    about: sanityBackgrounds {
      aboutPage {
        _key
        _type
        alt
        asset {
          fluid {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
      }
    }

    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    page: sanityPage(_id: { regex: "/(drafts.|)about/" }) {
      id
      title
      _rawPortablebody
    }

  }
`

const AboutPage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const about = (data || {}).about
  const site = (data || {}).site

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  const page = data && data.page

  if (!page) {
    throw new Error(
      'Missing "About" page data. Open the studio at http://localhost:3333 and add "About" page data and restart the development server.'
    )
  }

  return (

    <Layout pageLayout='normal-footer'>

      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      <article className='sections'>
        <section className='page-section white herstellung'>
          <div className='mainImage hero--subpage hero about'>

            <BackgroundImage
              Tag='section'
              style={{
                backgroundSize: 'cover',
                backgroundPosition: 'top center',
                backgroundRepeat: 'none',
                display: 'block',
                position: 'fixed',
                height: '100%',
                width: '100%',
                willChange: 'transform'
              }}
              fluid={about.aboutPage.asset.fluid}
              backgroundColor={`#040e18`}
              fadeIn={false}
            />
            <div className='content-wrapper design-page'>
              <div className='content'>

                <div className='normal-sections-wrapper'>

                  <div className='subsection'>
                    <h1>{page.title}</h1>

                    {page._rawPortablebody && <BlockContent blocks={page._rawPortablebody || []} />}
                  </div>

                </div>

              </div>
            </div>
          </div>

        </section>
      </article>

    </Layout>

  )
}

export default AboutPage
